<!--能人详情-->
<template>
    <div class="bestPerson">
        <div class="breadcrumb w-1400">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/xiangningqing'}">乡宁情</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/talents'}">能人介绍</el-breadcrumb-item>
                <el-breadcrumb-item >能人详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="p-list w-1400">
            <el-row :gutter="30">
                <el-col :span="18">
                    <div class="b-h2">
                        {{pageData.title}}
                    </div>
                    <div class="row sub-h2">
                        <div>{{pageData.releaseDate}}</div>
                        <div>浏览：142</div>
                        <div>评论：0</div>
                    </div>
                    <div class="b-content" v-html="pageData.content">
                        <p>
                                为总结牦牛藏羊高效养殖技术推广经验，加强工作交流，深入推进高效养殖技术推广工作，更好
                            地为生态畜牧业合作社发展服务，为“草地生态畜牧业试验区”服务，为 “牧业增效，牧民增收”服
                            务。近期，省畜牧总站在西宁举办全省牦牛藏羊高效养殖技术推广工作经验交流及培训，全省六州（
                            市）、县畜牧兽医站站长及项目负责人80余人参加了培训。
                        </p>
                        <div class="picbox">
                            <el-image style="width:100%;heihgt:100%" fit="cover" :src="require('../../../../public/img/xnq/zx1.png')"></el-image>
                        </div>
                        <p>
                                省畜牧总站有关负责人在开班仪式讲话中对技术推广提出了新要求。一是进一步明确全省牦牛藏
                            羊高效养殖技术推广工作思路，加大项目实施的监督指导。二是要不断提高专业技术人员的业务能力，
                            更好的指导专业合作社开展精准补饲，切实发挥好支撑作用。三是通过对牦牛藏羊高效养殖技术推广
                            工作总结、查漏补缺，突出工作重点。培训班邀请青海大学农牧学院教授讲授了牦牛、藏羊高效养殖
                            鉴定组群、养殖档案建立与管理、精准补饲等方面的技术知识。各州、县就2014—2016年以来我省
                            牦牛、藏羊高效养殖技术推广工作取得的成绩和存在问题进行了总结分析和经验交流。
                        </p>
                        <div class="picbox">
                            <el-image style="width:100%;heihgt:100%" fit="cover" :src="require('../../../../public/img/xnq/zx2.png')"></el-image>
                        </div>
                        <p>
                                  通过经验交流及培训，进一步提高了学员的理论水平和业务技能，达到了预期效果。学员们一致
                            反映本次工作经验交流及培训内容丰富、形式灵活，对解决实际问题具有较强的针对性。
                        </p>
                    </div>
                    <div class="pageTo">
                        <p>上一篇：已经是第一篇了</p>
                        <p>下一篇：百名能人回乡 传授养殖及畜牧经验，为振兴家乡尽一份力！</p>
                    </div>
                    <div class="comment">
                        <div class="comment-title">我来说两句</div>
                        <div class="comment-form">
                            <el-input v-model="comment" placeholder="我来说两句" style="width:calc(100% - 120px)"></el-input>
                            <el-button type="warning" > 登录并发布</el-button>
                        </div>
                        
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="p-r">
                        <div class="p-r-title row">
                            <div class="act">最新资讯</div>|
                            <div>热门资讯</div>
                        </div>
                        <div class="hot1 mt-20">
                            <div class="hot1-pic">
                                <el-image style="width:100%;heihgt:100%" fit="cover" :src="require('../../../../public/img/xnq/hot1.png')"></el-image>
                            </div>
                            <div class="hot1-title">西宁最新返乡政策</div>
                        </div>
                        <div class="hotlist clearfix" v-for="item in 5" :key="item + 'hoto'">
                            <div class="hotlist-pic fl">
                                <el-image style="width:100%;heihgt:100%" fit="cover" :src="require('../../../../public/img/xnq/hot2.png')"></el-image>
                            </div>
                            <div class="hot-text fl" >
                                <p>
                                    青稞种植时间与技术经验讲解
                                </p>    
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            
        </div>
    </div>
</template>
<script>
import thirdNav from '@/components/thirdNav'
import {GetDetail} from '@/api/xiangningqing'
export default {
    components:{
        thirdNav
    },
    data(){
        return{
            comment:'',
            pageData:{},
        }
    },
    methods:{
        getDetail(){
            GetDetail(this.$route.query.id).then(res=>{
                console.log(res.data);
                this.pageData = res.data.data;
            })
        }
    },
    created(){
        this.getDetail();
    }
}
</script>
<style scoped>
.hot-text p{
    display: table-cell;
    vertical-align: middle;
}
.hot-text{
    width: 40%;
    height: 94px;
    padding: 10px;
    display: table;
    color: #666;
    line-height: 18px;
    font-size: 14px;
}
.hotlist-pic{
    width: 60%;
    height: 94px;
    overflow: hidden;
}
.hotlist{
    padding: 15px 0;
    border-bottom: 1px solid #777;
}
.hot1{
    border-bottom: 1px solid #777;
}
.hot1-title{
    text-align: center;
    font-size: 18px;
    padding: 10px 0;
}
.p-r-title div:hover,.p-r-title div.act{
    background: #f89807;
    color: #fff;
}
.p-r-title div{
    padding:0 15px;
    margin: 0 5px;
}
.p-r{
    border: 1px solid #777;
    padding: 15px;
}
.p-r-title{
    font-size: 20px;
    line-height: 40px;
    justify-content: center;
}
.comment-form{
    margin-top: 20px
}
.comment-title{
    font-size: 18px;
    font-weight: 600;
}
.comment{
    margin-top: 20px;
    border-top: 2px solid #f89807;
    padding-top: 10px;
}
.pageTo p:hover{
    color: #0e6ac1;
    text-decoration: underline;
}
.pageTo p{
    padding: 5px 0;
}
.pageTo{
    margin-top: 40px;
    font-size: 16px;
}
.picbox{
    margin: 15px 0;
}
.b-content p{
    text-indent: 30px
}
.b-content{
    padding-top: 20px;
    font-size: 16px;
    line-height: 24px;
}
.sub-h2{
    font-size: 16px;
    color: #999;
    line-height: 30px;
    justify-content: space-between;
    border-bottom: 1px solid #777;
}
.b-h2{
    font-size: 20px;
    font-weight: 600;
    line-height: 40px
}
.bestPerson{
    font-family: '微软雅黑';
}
.breadcrumb >>> .el-breadcrumb .is-link,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner{
    font-size: 18px;
    font-family: '微软雅黑';
    color: #0e6ac1;
}
.breadcrumb{
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #0e6ac1;
    margin-bottom: 50px;
}
</style>